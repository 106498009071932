.App {
  text-align: center;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  background-color: #660000;
  height: 90px;
}

.vt-logo, .App img {
  height: 50px;
  margin-right: 10px;
}

h1 {
  color: white;
  margin: 0;
  padding: 15px;
  font-size: 24px;
  text-align: left;
}

.BeachMap {
  flex-grow: 1;
  position: relative;
  width: 100%;
  height: 100%;
}

.header-right {
  display: flex;
  align-items: center;
}

.search-bar {
  padding: 5px;
  margin-right: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
}

.header-button {
  color: white;
  background-color: #333;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.header-button:hover {
  background-color: #555;
}
.navbar-custom {
  background-color: #660000;
}

/* Optional: if you want to change the color of the links and other elements in the navbar */
.navbar-custom .navbar-brand,
.navbar-custom .navbar-nav .nav-link {
  color: white;
}

.navbar-custom .navbar-nav .nav-link:hover,
.navbar-custom .navbar-brand:hover {
  color: #ccc; /* Lighter color on hover */
}